import { useEffect, useRef, useCallback } from 'react';

function StickyHeader ( props ) {
    const { top } = props;
    const ref = useRef( null );

    const scrollHandler = useCallback(() => {
        let stickyContent = ref.current.children[ 0 ];
        if ( window.scrollY > top ) {
            if ( !stickyContent.classList.contains( 'fixed' ) ) {
                stickyContent.classList.add( 'fixed' );
                document.body.classList.add('sticky_class');
            }
        } else if ( stickyContent.classList.contains( 'fixed' ) ) {
            stickyContent.classList.remove( 'fixed' );
            document.body.classList.remove('sticky_class');
        }
    },[top])

    useEffect( () => {
        // router.events.on( 'hashChangeComplete', initSticky );
        scrollHandler();
        window.addEventListener( 'scroll', scrollHandler, false );

        window.addEventListener( 'resize', scrollHandler, false );

        return () => {
            window.removeEventListener( 'scroll', scrollHandler );
            window.removeEventListener( 'resize', scrollHandler );
        }
    },[scrollHandler])


    return (
        <div ref={ ref } className="sticky-wrapper">
            { props.children }
        </div>
    );
}

export default StickyHeader;