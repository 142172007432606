import React from 'react';


function Footer() {
    return (
    <div className='footer'>
        <iframe src={process.env.REACT_APP_IFRAME_URL} title='Footer' className='iframe-styles' scrolling='no' style={{border:"none", width:"100%", overflow:"hidden !important"}}></iframe>
    </div>
    );
}

export default React.memo( Footer );