import React from 'react';
// import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


function WishlistMenu ( props ) {
    const {wishlistCount} = props;

    return (
        <Link className="wishlist-link" to="/wishlist">
            <div className="icon">
                <i className="icon-heart-o"></i>
                <span className="wishlist-count badge">{ wishlistCount || "0" }</span>
            </div>
            <p>Wishlist</p>
        </Link>
    );
}
export default WishlistMenu;