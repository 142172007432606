import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CartList, DeleteCart } from "../../store/CartSlice";


function CartMenu() {
    const dispatch = useDispatch();
    var cartStatus = useSelector(state => state.cartState.cart)
    var CartData = useSelector(state => state.cartState);
    const CartLists = CartData.status === "success" && CartData.cart.CartItems;
    useEffect(() => {
        dispatch(CartList());
    },[dispatch])
    const subTotal = cartStatus.TotalCartPrice;

    function removeProFromCart(SKU){
        dispatch(DeleteCart(SKU));
        setTimeout( () => {
            dispatch(CartList());
        }, 700 );
    }
    return (
        <div className="dropdown cart-dropdown">
            <Link to="/cart" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                <i className="icon-shopping-cart"></i>
                <span className="cart-count">{cartStatus.TotalItems || "0"}</span>
                <span className="cart-txt">Cart</span>
            </Link>

            <div className={ `dropdown-menu dropdown-menu-right ${ CartLists? 'text-center' : '' }` } >
                {
                    CartLists.length < 1 ? <p>No products in the cart.</p>
                        :
                        <>
                            <div className="dropdown-cart-products">
                                {CartLists && CartLists.map( ( item, index ) => (
                                    <div className="product justify-content-between" key={ index }>
                                        <div className="product-cart-details">
                                            <h4 className="product-title">
                                                <Link to={ `/product/${ item.SKU }` }>{ item.Name }</Link>
                                            </h4>

                                            <span className="cart-product-info">
                                                <span className="cart-product-qty">{ item.Quantity } </span>
                                                 x ₹{item.SalePrice}
                                            </span>
                                        </div>

                                        <figure className="product-image-container ml-2">
                                            <Link to={ `/product/${ item.SKU }` } className="product-image">
                                                <img src={item.Images && item.Images[ 0 ].URL } alt="product" />
                                            </Link>
                                        </figure>
                                        <button className="btn-remove" title="Remove Product" onClick={ () => removeProFromCart( item.SKU ) }><i className="icon-close"></i></button>
                                    </div>
                                ) ) }
                            </div>
                            <div className="dropdown-cart-total">
                                <span>Total</span>

                                <span className="cart-total-price">₹{ subTotal }</span>
                            </div>
                            <div className="dropdown-cart-action">
                                <Link to="/cart" className="btn btn-primary">View Cart</Link>
                                <Link to={`${process.env.REACT_APP_ACCOUNT_URL}checkout/shop`} className="btn btn-outline-primary-2"><span>Checkout</span><i className="icon-long-arrow-right"></i></Link>
                            </div>
                        </>
                }
            </div>
        </div>
    );
}


export default CartMenu;