import React, { useCallback, useEffect, useState } from 'react';
import {useSelector } from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import GalleryDefault from '../features/GalleryDefault';
import InfoOne from '../features/InfoOne';
import ProductDetail from './ProductDetail';
import { instance } from '../../utils/ApiRequest';

function ProductDefault ({IsUser}) {
    const {SKU} = useParams();
    const [firstLoading, setFirstLoading] = useState(false);
    const [VariantDetails, setVariantDetails] = useState(null);
    const [variantLoaded, setVariantLoaded] = useState(false);
    const [product, setProduct] = useState(null);
    const navigate = useNavigate();
    
    // cart items
    var CartData = useSelector(state => state.cartState);
    const CartLists = CartData.status === "success" && CartData.cart.CartItems;

    // fetch variant details
    const FetchVariantBySKU = useCallback(async() => {
        setVariantLoaded(true);
        await instance.get(`/shop/products/variants/${SKU}${product === null ? '?includeProduct=true' : ''}`)
        .then(res => {
            if(res.data.Status === "Success"){
                setVariantDetails(res.data.Data);
                if(product === null){
                    setProduct(res.data.Data.Product);
                }
                setFirstLoading(true);
                setVariantLoaded(false);
            }
        }).catch(err => {
        })
    },[SKU, product])

    
    useEffect(() => {
        FetchVariantBySKU();
    }, [FetchVariantBySKU])

    var images = VariantDetails && VariantDetails?.Images;

    if (!SKU ) navigate('/');
    
    return (
        
        <div className="main">
            <div className="page-content mt-5">
                <div className="skeleton-body">
                    <div className="container product-details-top">
                        <div className={ `row skel-pro-single ${!firstLoading ? '' : 'loaded'}` }>
                            <div className="col-md-6">
                                <div className="skel-product-gallery"></div>
                                {
                                    firstLoading && !variantLoaded && VariantDetails !== null?
                                    <GalleryDefault product={ product } productImages={images}/>
                                    : <div>Loading</div>
                                }
                            </div>

                            <div className="col-md-6">
                                <div className="entry-summary row">
                                    <div className="col-md-12">
                                        <div className="entry-summary1 mt-2 mt-md-0"></div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="entry-summary2"></div>
                                    </div>
                                </div>
                                {
                                    firstLoading && !variantLoaded && VariantDetails !== null ?
                                        <ProductDetail 
                                        product={ product } 
                                        SKU={SKU}
                                        VariantDetails={VariantDetails} 
                                        CartLists={CartLists} 
                                        IsUser={IsUser}
                                    />
                                    : <div>Loading</div>
                                }
                            </div>
                        </div>
                        {
                            !firstLoading ?
                            <div className="skel-pro-tabs"></div>
                            :
                            <InfoOne product={ product } VariantDetails={VariantDetails} IsUser={IsUser}/>
                        }
                    </div>
                </div >
            </div >
        </div >
    )
}

export default ProductDefault;
