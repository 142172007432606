import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

function MobileMenu() {
    function toKebabCase(str) {
        return str
            .toLowerCase() 
            .replace(/\s+/g, '-') 
            .replace(/[^\w-]+/g, '');
    }
    const searchParams = new URLSearchParams(window.location.search);
    const search = searchParams.get('s');
    var categories = useSelector(state => state.categories);
    categories = categories && categories.categories.Data;

    function hideMobileMenu() {
        document.querySelector( 'body' ).classList.remove( 'mmenu-active' );
    }

    return (
        <div className="mobile-menu-container">
            <div className="mobile-menu-wrapper">
                <span className="mobile-menu-close" onClick={ hideMobileMenu }><i className="icon-close"></i></span>

                <Tabs defaultIndex={ 0 } selectedTabClassName="show">
                    <TabList className="nav nav-pills-mobile" role="tablist">
                        <Tab className="nav-item">
                            <span className="nav-link">Categories</span>
                        </Tab>
                        <Tab className="nav-item">
                            <span className="nav-link">Main menu</span>
                        </Tab>
                    </TabList>

                    <div className="tab-content">
                        <TabPanel>
                            <nav className="mobile-nav">
                                <ul className="mobile-menu">
                                {categories && categories.map((item, index) => {
                                    const cat_item = toKebabCase(item.Name);
                                    return (
                                        <li key={index}><Link to={`/categories/${cat_item}/?s=${search || ''}&cat=${item.Key || ''}`} className="sf-with-ul" onClick={hideMobileMenu}>{item.Name}</Link></li>
                                    )}
                                )}
                                </ul>
                            </nav>
                        </TabPanel>
                    </div>
                    <div className="tab-content">
                        <TabPanel>
                            <nav className="mobile-nav">
                                <ul className="mobile-menu">
                                    <li>
                                        <a href={process.env.REACT_APP_HOME_URL} className="sf-with-ul">Home</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_SHOP_URL}`} className="sf-with-ul" scroll={ false }>Shop</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_PRINT_URL}`} className="sf-with-ul">Print</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_SUPPORT_URL}`} className="sf-with-ul">Support</a>
                                    </li>
                                </ul>
                            </nav>
                        </TabPanel>
                    </div>
                </Tabs>

                <div className="social-icons">
                    <Link to="https://www.facebook.com/ocean3d.in" className="social-icon" title="Facebook"><i className="icon-facebook-f"></i></Link>
                    <Link to="https://www.youtube.com/@ocean3dindia" className="social-icon" title="Instagram"><i className="icon-instagram"></i></Link>
                    <Link to="https://x.com/ocean3dindia" className="social-icon" title="Twitter"><i className="fa-brands fa-x-twitter"></i></Link>
                    <Link to="https://www.instagram.com/ocean3d.in" className="social-icon" title="Youtube"><i className="icon-youtube"></i></Link>
                </div>
            </div>
        </div>
    )
}

export default React.memo( MobileMenu );