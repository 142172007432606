import React, { useState, useEffect } from 'react';
import ProductEleven from '../Products/ProductColumnView';
import ProductNine from '../Products/ProductListView';

function ShopListOne ( props ) {
    const { loading, products, perPage, type, toggle} = props;
    const [ fakeArray, setFakeArray ] = useState( [] );
    const [ gridClass, setGridClass ] = useState( 'col-6' );

    useEffect( () => {
        let temp = [];
        for ( let i = 0; i < perPage; i++ ) {
            temp.push( i );
        }
        setFakeArray( temp );
    }, [ perPage ] )
    useEffect( () => {
        if ( type === 'list' || type === '2cols' ) setGridClass( 'col-6' );
        if ( type === '3cols' ) setGridClass( 'col-6 col-md-4 col-lg-4' );
        if ( type === '4cols' )
            setGridClass( 'col-6 col-md-4 col-lg-4 col-xl-3' );
    }, [ type ] )

    return (
        <div className="products">
            {
                ( products.length < 1 && loading ) ?
                    <p
                        className="no-results"
                    >No products matching your selection.</p>
                    :
                    <>
                        {
                            type === 'list' ?
                                !loading ?
                                    fakeArray.map( ( item, index ) => (
                                        <div className="skel-pro skel-pro-list" key={ index }></div>
                                    ) )
                                    :
                                    products.map( ( product, index ) => (
                                        <ProductNine
                                            product={ product }
                                            key={ index }
                                            toggle={toggle}
                                        />
                                    ) )
                                :
                                <div className="row">
                                    {
                                        !loading ?
                                            fakeArray.map( ( item, index ) => (
                                                <div className={ gridClass } key={ index }>
                                                    <div className="skel-pro"></div>
                                                </div>
                                            ) )
                                            :
                                            products.map( ( product, index ) => (
                                                <div className={ gridClass } key={ index }>
                                                    <ProductEleven product={ product } toggle={toggle} />
                                                </div>
                                            ) )
                                    }
                                </div>
                        }

                    </>
            }
        </div>
    )
}

export default React.memo( ShopListOne );