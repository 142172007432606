import React from 'react'
import { Helmet } from 'react-helmet-async'

function SEO({title, description, name, type, link}) {
  return (
    <Helmet>
        { /* Standard metadata tags */ }
        <title>{title}</title>
        <meta name='description' content={description} />
        { /* End standard metadata tags */ }
        { /* Facebook tags */ }
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://yourwebsite.com/path/to/product-image.jpg" />
        <meta property="og:url" content="https://yourproductpage.com" />
        <meta property="og:type" content="website" />
        { /* End Facebook tags */ }
        { /* Twitter tags */ }
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        { /* End Twitter tags */ }
        {/* canonical */}
        <link rel="canonical" href={link}></link>
    </Helmet>
  )
}

export default SEO