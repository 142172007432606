import React, { useState, useEffect } from 'react';
import Qty from '../features/Qty';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CartList, DeleteCart, UpdateCart } from '../../store/CartSlice';
import useSize from '../features/useSize';
import SEO from '../SEO/SEO';
import PageHeader from '../features/PageHeader';

function Cart ( ) {
    const [cartItems, setCartItems] = useState([]);
    const size = useSize();

    const dispatch = useDispatch();
    var CartData = useSelector(state => state.cartState);
    const CartLists = CartData.status === "success" && CartData.cart.CartItems;
    const subtotal = CartData.status === "success" && CartData.cart.TotalCartPrice;

    const ids = cartItems.map(({ SKU }) => SKU);
    const filtered = cartItems.filter(({ SKU }, index) => !ids.includes(SKU, index + 1));

    useEffect(() => {
        dispatch(CartList());
    },[dispatch])

    function handleQuantityChange ( value, SKU ) {
        // console.log(`Updating SKU ${SKU} to quantity ${value}`);
        const existingItemIndex = filtered.findIndex(item => item.SKU === SKU);
        if (existingItemIndex !== -1) {
            const updatedCart = [...filtered];
            updatedCart[existingItemIndex].Quantity = value;
            setCartItems(updatedCart);
        } else {
            setCartItems(prevCart => [...prevCart, { SKU, Quantity: value }]);
        }
    }
    
    function removeProFromCart(SKU){
        dispatch(DeleteCart(SKU));
        setTimeout( () => {
            dispatch(CartList());
        }, 400 );
    }
    function updateCart ( e ) {
        let button = e.currentTarget;
        button.querySelector( '.icon-refresh' ).classList.add( 'load-more-rotating' );
        dispatch(UpdateCart(cartItems))
        setTimeout( () => {
            dispatch(CartList());
            button.querySelector( '.icon-refresh' ).classList.remove( 'load-more-rotating' );
        }, 300 );
    }
    return (
        <div className="main">
            <SEO 
                title='Ocean 3D Shop Cart - Checkout Our Exclusive 3D Oceanic Products'
                description='View your Ocean3D Shop cart for high-quality 3D oceanic products. 
                            Checkout securely and explore marine life models, ocean-themed decor, and more. 
                            Start shopping now!'
                name='Ocean3dShop'
                type='article'
                link= {window.location.href}
            />
             <PageHeader title={"Cart"} subtitle="" />
            <div className="page-content pb-5 pt-2">
                <div className="cart">
                    <div className="cart-items container">
                        {
                            CartData.status === "success" ? 
                            CartLists.length > 0 ?
                                <div className="row">
                                    <div className="col-lg-9">
                                        {
                                        size < 768 ? 

                                            <div className='mobile_table_view'>
                                                {
                                                CartLists && CartLists.map( ( product, index ) => {
                                                    let size = product.Size === "SM" ? "Small" : product.Size === "MD" ? "Medium" : product.Size === "RG" ? "Regular" : "";
                                                    return(
                                                        <div className='item' key={index}>
                                                            <figure className="product-media-loaded">
                                                                <Link to={ `/product/${product.SKU}` } className="product-image">
                                                                    <img src={product.Images[0].URL } alt = { product.Name } />
                                                                </Link>
                                                            </figure>
                                                            <div className='product-details'>
                                                                <h4 className="product-title">
                                                                    <Link to={ `/product/${product.SKU}` }>{ product.Name }</Link>
                                                                </h4>
                                                                <span className='mat-col'>
                                                                    {product.Material.Name} - {product.Color.Name} 
                                                                </span>
                                                                <span className='addional'>
                                                                    <span className='mat-col'><span className='strong'>Size:</span> {size}</span>
                                                                    <span className="out-price">₹<del>{ product.Price }</del>{" "}{product.SalePrice}</span>
                                                                </span>
                                                                <div className='actions'>
                                                                    <div className="dropdown">
                                                                        <Qty value={ product.Quantity } changeQty={ (current) => handleQuantityChange( current, product.SKU ) } adClass="cart-product-quantity"></Qty>
                                                                    </div>
                                                                    <button
                                                                        className="btn-remove"
                                                                        onClick={ () => removeProFromCart( product.SKU ) }
                                                                    >
                                                                        <i className="icon-close"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            :
                                            <table className="table table-cart table-mobile">
                                                <thead>
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Sze</th>
                                                        <th>Material</th>
                                                        <th>Color</th>
                                                        <th>Price</th>
                                                        <th>Quantity</th>
                                                        <th>Total</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    { CartLists.length > 0 ?
                                                        CartLists && CartLists.map( ( item, index ) => {
                                                            let size = item.Size === "SM" ? "Small" : item.Size === "MD" ? "Medium" : item.Size === "RG" ? "Regular" : "";
                                                            return (
                                                                <tr key={ index }>
                                                                    <td className="product-col">
                                                                        <div className="product">
                                                                            <figure className="product-media">
                                                                                <Link to={ `/product/${item.SKU}` } className="product-image">
                                                                                    <img src={item.Images[ 0 ].URL } alt="product" />
                                                                                </Link>
                                                                            </figure>
                                                                            <div className='product-disc'>
                                                                                <h4 className="product-title"><Link to={ `/product/${item.SKU}` }>{ item.Name }</Link></h4>
                                                                                {/* <div className="ratings">
                                                                                <div className="ratings-val" style={ { width: product.AvgRating * 20 + '%' } }></div>
                                                                                    <span className="tooltip-text">{ product.AvgRating.toFixed( 2 ) }</span>
                                                                                </div> */}
                                                                                <span data-toggle="tooltip" data-placement="top" title={item.ShortDescription} >
                                                                                    {item.ShortDescription.substr(0, 40)}{item.ShortDescription.length > 40 && "...."}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className="price-col">
                                                                        {size}
                                                                    </td>
                                                                    <td className="price-col">
                                                                        {item.Material.Name}
                                                                    </td><td className="price-col">
                                                                        {item.Color.Name}
                                                                    </td>
                                                                    <td className="price-col">
                                                                        ₹{item.SalePrice}
                                                                    </td>

                                                                    <td className="quantity-col">
                                                                        <Qty value={ item.Quantity } changeQty={ (current) => handleQuantityChange( current, item.SKU ) } adClass="cart-product-quantity"></Qty>
                                                                    </td>

                                                                    <td className="total-col">
                                                                        ₹{ item.TotalItemPrice }
                                                                    </td>

                                                                    <td className="remove-col">
                                                                        <button className="btn-remove" onClick={ () => removeProFromCart( item.SKU ) }><i className="icon-close"></i></button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                        ) :
                                                        <tr>
                                                            <td>
                                                                <p className="pl-2 pt-1 pb-1"> No Products in Cart </p>
                                                            </td>
                                                        </tr>
                                                    }

                                                </tbody>
                                            </table>
                                        }
                                        {/* update cart items button */}
                                        {/* {
                                            size > 767 &&
                                            <div className="cart-bottom">
                                                <button className="gradient_btn" onClick={ updateCart }>UPDATE CART<i className="icon-refresh"></i></button>
                                            </div>
                                        } */}
                                        {/* actions if cart more than 4 in mobile */}

                                        { (CartLists.length > 4 && size < 768) &&
                                        <div className='conditional-btns'>
                                            <div className='actions mb-2'>
                                                <Link to="/" className="gradient_btn btn-block">CONTINUE SHOPPING</Link>
                                                <div className="cart-bottom">
                                                    <button className="gradient_btn" onClick={ updateCart }>UPDATE CART<i className="icon-refresh"></i></button>
                                                </div>
                                            </div>
                                            <aside className='col-lg-3 mobile_cart_summary_aside'>
                                                <div className='mobile_cart_summary'>
                                                    <div className='cart-total'>
                                                        <span className="summary-title">Cart Total : </span>
                                                        <span>₹{ subtotal}</span>
                                                    </div>
                                                    <Link
                                                        className={`${CartLists.length > 0 ? 'gradient_btn btn-order btn-block' : 'btn-disabled'}`}
                                                        to={`${process.env.REACT_APP_ACCOUNT_URL}checkout/shop`}
                                                    >
                                                        PROCEED TO CHECKOUT
                                                    </Link>
                                                </div>
                                            </aside>
                                        </div>
                                        
                                        }
                                    </div>
                                    {/* mobile element */}
                                    {
                                    size < 768 ?
                                    <aside className='col-lg-3 mobile_cart_summary_aside'>
                                        <div className='mobile_cart_summary'>
                                            <div className='cart-total'>
                                                <span className="summary-title">Cart Total : </span>
                                                <span>₹{ subtotal}</span>
                                            </div>
                                            <Link
                                                className={`${CartLists.length > 0 ? 'gradient_btn btn-order btn-block' : 'btn-disabled'}`}
                                                to={`${process.env.REACT_APP_ACCOUNT_URL}checkout/shop`}
                                            >
                                                PROCEED TO CHECKOUT
                                            </Link>
                                        </div>
                                        <div className='actions'>
                                            <Link to="/" className="gradient_btn btn-block">CONTINUE SHOPPING</Link>
                                            <div className="cart-bottom">
                                                <button className="gradient_btn" onClick={ updateCart }>UPDATE CART<i className="icon-refresh"></i></button>
                                            </div>
                                        </div>
                                    </aside>
                                    :
                                    <aside className="col-lg-3 desktop-cart-shipping">
                                        <div className="summary summary-cart">
                                            <h3 className="summary-title">Cart Total</h3>
                                            <table className="table table-summary">
                                                <tbody>
                                                    <tr className="summary-subtotal">
                                                        <td>Subtotal:</td>
                                                        <td>₹{ subtotal}</td>
                                                    </tr>
                                                    <tr className="summary-shipping">
                                                        <td>Shipping:</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    <tr className="summary-shipping text-center">
                                                        <td>Shipping options are available on checkout</td>
                                                        <td>&nbsp;</td>
                                                    </tr>

                                                    <tr className="summary-total">
                                                        <td>Total:</td>
                                                        <td>₹{ subtotal }</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                            <Link
                                                className={`${CartLists.length > 0 ? 'gradient_btn btn-order btn-block' : 'btn-disabled'}`}
                                                to={`${process.env.REACT_APP_ACCOUNT_URL}checkout/shop`}
                                            >
                                                PROCEED TO CHECKOUT
                                            </Link>
                                        </div>
                                        <div className='actions'>
                                            <div className="cart-bottom">
                                                <button className="gradient_btn" onClick={ updateCart }>UPDATE CART<i className="icon-refresh"></i></button>
                                            </div>
                                            <Link to="/" className="gradient_btn btn-block mb-3">CONTINUE SHOPPING</Link>
                                        </div>
                                    </aside>
                                    }
                                </div>
                                :
                                <div className="row">
                                    <div className="col-12">
                                        <div className="cart-empty-page text-center">
                                            <i className="cart-empty icon-shopping-cart" style={ { lineHeight: 1, fontSize: '15rem' } }></i>
                                            <p className="px-3 py-2 cart-empty mb-3">No products added to the cart</p>
                                            <p className="return-to-shop mb-0">
                                                <Link
                                                    to="/"
                                                    className="gradient_btn"
                                                >RETURN TO SHOP</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div>Loading</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Cart;