import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Qty from '../features/Qty';
import { useDispatch, useSelector } from 'react-redux';
import { AddToWishList, fetchWishlistItems } from '../../store/WhishlistSlice';
import { AddToCart, CartList, UpdateCart } from '../../store/CartSlice';
import ColorDropdown from './ColorDropdown';
import SEO from '../SEO/SEO';
import { calculateDiscountPercentage, gtagSendEvent, toKebabCase } from '../../utils/ApiRequest';

function ProductDetail(props) {
    const ref = useRef(null);
    const { product, VariantDetails, IsUser } = props;
    const { SKU } = useParams();
    const navigate = useNavigate();
    const [copied, setCopied] = useState(false);
    const [qty, setQty] = useState(1);

    const location = useLocation();
    const pathnames = location.pathname.split('/').filter(x => x);

    const CartData = useSelector(state => state.cartState);
    const CartLists = CartData.status === "success" && CartData.cart.CartItems;

    const defaultVariant = {
        color: VariantDetails.Color.Key || "",
        material: VariantDetails.Material.Key || "",
        size: VariantDetails.Size || ""
    };
    const [selectedVariant, setSelectedVariant] = useState(defaultVariant);
    let Key = product?.Key;

    const isItemInCart = CartLists && CartLists.some(item => item.SKU === SKU);

    useEffect(() => {
        if (isItemInCart) {
            const cartItem = CartLists.find(item => item.SKU === SKU);
            setQty(cartItem ? cartItem.Quantity : 1);
        }
    }, [isItemInCart, CartLists, SKU]);

    const dispatch = useDispatch();
    const wishlistItems = useSelector(state => state.whishlist);

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler, { passive: true });
        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);

    const copy = (code) => {
        navigator.clipboard.writeText(code);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    function scrollHandler() {
        let stickyBar = ref.current.querySelector('.sticky-bar');
        if (stickyBar.classList.contains('d-none') && ref.current.getBoundingClientRect().bottom < 600) {
            stickyBar.classList.remove('d-none');
            return;
        }
        if (!stickyBar.classList.contains('d-none') && ref.current.getBoundingClientRect().bottom > 600) {
            stickyBar.classList.add('d-none');
        }
    }

    let colors = product?.MaterialOptions.find(ele => ele.Key === selectedVariant.material);

    const isInWishList = wishlistItems.whishlist.Data ? wishlistItems.whishlist.Data.some(item => item.SKU === SKU) : false;

    function onWishlistClick(e) {
        e.preventDefault();
        if (IsUser && IsUser.User) {
            dispatch(AddToWishList({ "SKU": SKU }));
            setTimeout(() => dispatch(fetchWishlistItems()), 500);
        }
    }

    function selectColor(item) {
        let material = selectedVariant.material;
        let color = item ? item : selectedVariant.color;
        let size = selectedVariant.size;
        setSelectedVariant({ ...selectedVariant, color: item });
        let updatedSKU = size + material + color + Key;
        navigate(`/product/${updatedSKU}`);
    }

    function selectMaterial(e) {
        let material = e.target.value || selectedVariant.material;
        let color = product?.MaterialOptions.find(ele => ele.Key === e.target.value).ColorOptions[0].Key;
        setSelectedVariant({
            ...selectedVariant,
            material: e.target.value,
            color: color
        });
        let updatedSKU = size + material + color + Key;
        navigate(`/product/${updatedSKU}`);
    }

    function selectSize(e) {
        let size = e.target.value || selectedVariant.size;
        setSelectedVariant({ ...selectedVariant, size: size });
        let updatedSKU = size + material + color + Key;
        navigate(`/product/${updatedSKU}`);
    }

    function onChangeQty(current) {
        setQty(current);
    }

    let material = selectedVariant.material || VariantDetails.Material.Key;
    let color = selectedVariant.color || VariantDetails.Color.Key;
    let size = selectedVariant.size || VariantDetails.Size;
    const updatedSKU = size + material + color + Key;

    function getProQty(){
        if (isItemInCart) {
            const cartItem = CartLists.find(item => item.SKU === SKU);
            return cartItem ? cartItem.Quantity : qty;
        }
    }
    
    function onCartClick(e) {
        e.preventDefault();
        if (e.currentTarget.classList.contains('btn-disabled')) return;
        let newProduct = {
            "SKU": updatedSKU,
            "Quantity": qty
        };
        const update_cart = [
            {
                "SKU": updatedSKU,
                "Quantity": qty
            }
        ]
        if (isItemInCart) {
            const proQty = getProQty();
            if(proQty !== qty){
                // gtagSendEvent();
                dispatch(UpdateCart(update_cart));
            }else{
                navigate('/cart');
            }
        } else {
            gtagSendEvent();
            dispatch(AddToCart(newProduct));
        }
        setTimeout(() => dispatch(CartList()), 250);
    }

    if (!product) {
        return <div></div>;
    }

    return (
        <div className="product-details product-summary-shadow" ref={ref}>
            <SEO
                title={`Ocean 3D Shop - ${product.Name}`}
                description={product.Description}
                name='Ocean3dShop'
                type='article'
                link={window.location.href}
            />
            <ul>
                <li><Link to='/categories/all-categories'>Shop</Link></li>
                <li>/</li>
                <li><Link to={`/categories/${toKebabCase(product.Categories[0].Name)}`}>{product.Categories[0].Name}</Link></li>
                {pathnames.map((value, index) => {
                    const pathname = `/${pathnames.slice(0, index + 1).join('/')}`;
                    return (
                        <React.Fragment key={pathname}>
                            <li>/</li>
                            <li>
                                {index === pathnames.length - 1 ? (
                                    value.charAt(0).toUpperCase() + value.slice(1)
                                ) : (
                                    <Link to={pathname}>
                                        {value.charAt(0).toUpperCase() + value.slice(1)}
                                    </Link>
                                )}
                            </li>
                        </React.Fragment>
                    );
                })}
            </ul>
            <h1 className="product-title">{`${product.Name} | ${VariantDetails.Material.Name} Material | ${VariantDetails.Color.Name} Color`}</h1>
            <div className="ratings-container">
                <div className="ratings">
                    <div className="ratings-val" style={{ width: product.AvgRating * 20 + '%' }}></div>
                    <span className="tooltip-text">{product.AvgRating.toFixed(2)}</span>
                </div>
                <span className="ratings-text">( {product.TotalRatings} Reviews )</span>
            </div>

            <div className="product-price">
                <span className="out-price">
                    <span>₹{VariantDetails.SalePrice.toFixed(2)} <del>₹{VariantDetails.Price.toFixed(2)}</del>{" "}</span>
                </span>
                <span className="discount">{"-" + calculateDiscountPercentage(VariantDetails.Price.toFixed(2), VariantDetails.SalePrice.toFixed(2))}</span>
            </div>
            {product?.MaterialOptions?.length > 0 ?
                <>
                    <div className='details-filter-row details-row-size'>
                        <label className='mr1'>Material:</label>
                        {product.MaterialOptions.length > 1 ?
                            <div className="select-custom">
                                <select
                                    name="material"
                                    className="form-control"
                                    value={selectedVariant.material}
                                    onChange={selectMaterial}
                                >
                                    {product.MaterialOptions.map((item, index) => (
                                        <option
                                            defaultValue={SKU.includes(item.Key)}
                                            value={item.Key}
                                            key={index}
                                        >{item.Name}</option>
                                    ))}
                                </select>
                            </div>
                            :
                            <span>{product.MaterialOptions[0].Name}</span>
                        }
                    </div>
                    <div className="details-filter-row details-row-size">
                        <label className='mr1'>Color:</label>
                        {colors.ColorOptions.length > 1 ?
                            <ColorDropdown colors={colors} SKU={SKU} onChange={selectColor} />
                            :
                            <div className="select-header product-nav product-nav-dots selected">
                                <span
                                    className='active'
                                    style={{ backgroundColor: colors.ColorOptions[0].ColorHexCode }}
                                ></span>
                                <p>{colors.ColorOptions[0].Name}</p>
                            </div>
                        }
                    </div>
                    <div className="details-filter-row details-row-size">
                        <label className='mr1'>Size:</label>
                        {product.SizeOptions.length > 1 ?
                            <div className="select-custom">
                                <select
                                    name="size"
                                    className="form-control"
                                    value={selectedVariant.size}
                                    onChange={selectSize}
                                >
                                    {product && product.SizeOptions.map((item, index) => (
                                        <option
                                            defaultValue={SKU.includes(item.Key)}
                                            value={item.Key}
                                            key={index}
                                        >{item.Name}</option>
                                    ))}
                                </select>
                            </div>
                            :
                            <span>{product.SizeOptions[0].Name}</span>
                        }
                    </div>
                </>
                :
                ""
            }

            <div className="details-filter-row details-row-size">
                <label className='mr1'>Qty:</label>
                <Qty value={qty} max={100} changeQty={(current) => onChangeQty(current)} />
            </div>

            <div className="product-details-action">
                <span
                    className='btn-product gradient_btn btn-cart'
                    onClick={e => onCartClick(e)}
                >
                    <span>{
                        isItemInCart ?
                        getProQty() !== qty ?
                            "Update Cart" :
                            "View Cart" :
                            "Add to Cart"
                        }</span>
                </span>
                {
                    IsUser && IsUser.User !== null ?
                        isInWishList ?
                            <Link to="/wishlist" className={`btn-product btn-wishlist added-to-wishlist`}><span>Go to Wishlist</span></Link>
                            :
                            <Link href="#" className={`btn-product btn-wishlist ${IsUser && IsUser.User !== null ? '' : 'btn-disabled'}`} onClick={onWishlistClick}><span>Add to Wishlist</span></Link>
                        :
                        <div className='details-action-wrapper'>
                            <span>please</span>&nbsp; &nbsp;
                            <Link to={process.env.REACT_APP_ACCOUNT_URL + 'login?redirectTo=' + window.location.href} className='login'>login</Link>&nbsp; &nbsp;
                            <span>/</span>&nbsp; &nbsp;
                            <Link to={process.env.REACT_APP_ACCOUNT_URL + 'login?redirectTo=' + window.location.href} className='signup'>signup</Link>
                            &nbsp; &nbsp;<span>to use wishlist</span>
                        </div>
                }
            </div>

            <div className="product-details-footer">
                <div className="product-cat w-100 text-truncate">
                    <span>Category:</span>
                    {product.Categories.map((cat, index) => (
                        <span key={index}>
                            <Link
                                to={`/categories/${toKebabCase(cat.Name)}`}
                            >{cat.Name}</Link>
                            {index < product.Categories.length - 1 ? ',' : ''}
                        </span>
                    ))}
                </div>

                <div className="social-icons social-icons-sm">
                    <span className="social-label">Share:</span>
                    <Link to={`https://www.facebook.com/sharer?_encoding=UTF8&u=${window.location.href}&hashtag=Ocean3d`} className="social-icon" target='_blank' rel="noreferrer" title="Facebook">
                        <i className="icon-facebook-f"></i>
                    </Link>
                    <Link to={`https://twitter.com/intent/post?text=${encodeURI(product.Name)}&url=${window.location.href}&hashtags=ocean3d&via=ocean3dindia`} target='_blank' rel="noreferrer" className="social-icon" title="Twitter">
                        <i className="fa-brands fa-x-twitter"></i>
                    </Link>
                    <div>
                        <span className="social-icon" title="Copy link" onClick={() => copy(window.location.href)}>
                            <i className="fa-regular fa-copy"></i>
                        </span>
                        <span className='copy-icon'>{copied ? "Copied" : ""}</span>
                    </div>
                </div>
            </div>
            <div className="sticky-bar d-none">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="product-media">
                                <Link to={`/product/${SKU}`}>
                                    <img src={VariantDetails?.Images[0].URL} alt="product" />
                                </Link>
                            </figure>
                            <h3 className="product-title">
                                <Link to={`/product/${SKU}`}>{product.Name}</Link>
                            </h3>
                        </div>
                        <div className="col-6 justify-content-end">
                            <div className="product-price">
                                <span>₹<del>{VariantDetails.Price.toFixed(2)}</del>{" "}₹{VariantDetails.SalePrice.toFixed(2)}</span>
                            </div>
                            <Qty value={qty} max={100} changeQty={(current) => onChangeQty(current)} />
                            <div className="product-details-action">
                                <Link
                                    href="#"
                                    className='gradient_btn btn-product btn-cart'
                                    onClick={e => onCartClick(e)}
                                >
                                    <span>{
                                        isItemInCart ?
                                        getProQty() !== qty ?
                                            "Update Cart" :
                                            "View Cart" :
                                            "Add to Cart"
                                        }</span>
                                </Link>
                                {
                                    IsUser && IsUser.User !== null &&
                                    (isInWishList ?
                                        <Link to="/wishlist" className="btn-product btn-wishlist added-to-wishlist"><span>Go to Wishlist</span></Link>
                                        :
                                        <span className="btn-product btn-wishlist" onClick={onWishlistClick}><span>Add to Wishlist</span></span>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;
