import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchCategorisList } from '../../store/Categories';

function HeaderSearch () {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams(window.location.search);
    const search = searchParams.get('s');
    const catgory = searchParams.get('cat');
    var mat = searchParams.get('mat');
    var col = searchParams.get('col');
    const srt = searchParams.get('sort');
    mat = mat && mat.split(',');
    col = col && col.split(',');
    const [ cat, setCat ] = useState( catgory );
    const [ searchTerm, setSearchTerm ] = useState( search );
    var categories = useSelector(state => state.categories);
    categories = categories && categories.categories.Data;

    useEffect( () => {
        dispatch(fetchCategorisList());
    }, [dispatch] );
    function onCatSelect ( e ) {
        setCat( e.target.value );
    }
    function onSearchChange ( e ) {
        setSearchTerm( e.target.value );
    }
    function showSearchForm ( e ) {
        document
        .querySelector( '.header .header-search' )
        .classList.add( 'show' );
    }
    function onSubmitSearchForm ( e ) {
        e.preventDefault();
        navigate(`/?s=${searchTerm || ''}&cat=${cat ? cat.join(",") : ''}&mat=${mat ? mat.join(",") : ''}&col=${col ? col.join(",") : ''}&sort=${srt || ''}`);
    }
    function ClearSearch(){
        if(search){
            navigate(`/?s=${''}&cat=${cat ? cat.join(",") : ''}&mat=${mat ? mat.join(",") : ''}&col=${col ? col.join(",") : ''}&sort=${srt || ''}`);
        }
        setSearchTerm("");
    }

    return (
        <div className="header-search header-search-extended header-search-visible header-search-no-radius d-none d-lg-block">
            <button className="search-toggle"><i className="icon-search"></i></button>
            <form action="#" method="get" onSubmit={ onSubmitSearchForm } onClick={ showSearchForm }>
                <div className="header-search-wrapper search-wrapper-wide">
                    <div className="select-custom" onChange={ ( e ) => onCatSelect( e ) }>
                        <select id="cat" name="cat">
                            <option value={ '' }>All Categories</option>
                            {categories && categories.map((item, index) => 
                                <option value={item.Key} key={index}>{item.Name}</option>
                                )}
                        </select>
                    </div>
                    <label htmlFor="q" className="sr-only" value={ searchTerm }></label>
                    <input type="text" onChange={ onSearchChange } value={ searchTerm } className="form-control" name="q" placeholder="Search product ..." />
                    <button className="close-icon" type="reset" style={{display: searchTerm ? "block" : "none"}} onClick={ClearSearch}></button>
                    <button className="btn btn-primary" type="submit"><span className="sr-only">Search</span><i className="icon-search"></i></button>
                </div>
            </form>
        </div >
    );
}

export default  HeaderSearch;