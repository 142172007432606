import axios from "axios";

export function hideSidebar() {
  document.querySelector("body").classList.remove("sidebar-filter-active");
  document.querySelector("body").classList.remove("sort-filter-active");
}

export function toKebabCase(str) {
  return str
      .toLowerCase() 
      .replace(/\s+/g, '-') 
      .replace(/[^\w-]+/g, '');
}

export function toTitleCase(str) {
  return str
  .split('-')                   
  .map(word =>  word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');    
}

export const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL, // Replace with your API URL
    withCredentials: true // Enable sending cookies when making requests
});

// Function to start loader
const startLoader = () => {
    const loaderElement = document.getElementById('loader');
    if (loaderElement) {
      loaderElement.style.display = 'block';
    }
  };
  
  // Function to stop loader
  const stopLoader = () => {
    const loaderElement = document.getElementById('loader');
    if (loaderElement) {
      loaderElement.style.display = 'none';
    }
  };

  instance.interceptors.request.use(
    (config) => {
      // need to show loader
      startLoader();
      return config;
    },
    (error) => {
      stopLoader();
      return Promise.reject(error);
    }
  );
  
  // Add a response interceptor to decrement counter and handle errors
  instance.interceptors.response.use(
    (response) => {
      // stop the loader
      stopLoader();
      return response;
    },
    (error) => {
    // navigate
      if(error.response){
        // alert(GlobalError);
      }
      stopLoader();
      return Promise.reject(error);
    }
  );


// set session cookie
export function setSessionCookie(){
    instance.post('/account/users/create-session')
    .then(res => {
    }).catch(err => {
    })
}

// Calculate the discount percentage
export const calculateDiscountPercentage = (original, sale) => {
  if (original <= 0) return 0;
  return ((original - sale) / original) * 100 + "% off";
};

   //validate session cookie
 export function validateSessionCookie(){
    instance.get('/account/users/validate-session')
    .then(res => {
      
    }).catch(err => {
        setSessionCookie();
    })
}

export function gtagSendEvent(url) {
  var callback = function () {
    if (typeof url === 'string') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion_event_add_to_cart', {
    'event_callback': callback,
    'event_timeout': 2000,
  });
  return false;
}