import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import { calculateDiscountPercentage } from '../../utils/ApiRequest';


function ProductColumnView ( props ) {
    const { product, toggle} = props;

    return (
        <div className="product product-7 text-center w-100">
            <Link to={ `/product/${product.Variant}` } target={!toggle ? "_blank" : "_self"}>
                <figure className="product-media-loaded">
                    <LazyLoadImage
                        alt={ product.Image.Alt }
                        src={ product.Image.ThumbnailURL }
                        threshold={ 500 }
                        effect="black and white"
                        wrapperClassName="product-image"
                    />
                </figure>
                <div className="product-body">
                    <h3 className="product-title">
                        <Link to={ `/product/${product.Variant}` } target={!toggle ? "_blank" : "_self"}>{ product.Name }</Link>
                    </h3>

                    <div className="product-price-">
                        <span className="out-price">₹{ product.MinSalePrice }<del>₹{ product.MinPrice }</del>{" "}<span className="discount"> { "-" + calculateDiscountPercentage(product.MinPrice, product.MinSalePrice)}</span></span>
                    </div>
                    <div className="ratings-container">
                        <div className="ratings">
                            <div className="ratings-val" style={ { width: product.AvgRating * 20 + '%' } }></div>
                            <span className="tooltip-text">{ product.AvgRating.toFixed( 2 ) }</span>
                        </div>
                        <span className="ratings-text">( { product.TotalRatings } Reviews )</span>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ProductColumnView