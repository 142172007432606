import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../utils/ApiRequest";

//add to cart
export const AddToCart = createAsyncThunk("cart/AddToCart", async (data) => {
    try{
        const response = await instance.post(`/shop/carts/add`, data);
        return response.data;
    }catch(error){
        throw error;

    }
});

// cart list
export const CartList = createAsyncThunk("cart/CartList", async () => {
    try{
        const response = await instance.get(`/shop/carts/fetch`);
        return response.data.Data;
    }catch(error){
        throw error;

    }
});

//update cart
export const UpdateCart = createAsyncThunk("cart/UpdateCart", async (data) => {
    try{
        const response = await instance.put(`/shop/carts/update`, data);
        return response.data;
    }catch(error){
        throw error;
    }
});

//delete cart
export const DeleteCart = createAsyncThunk("cart/DeleteCart", async (SKU) => {
    try{
        const response = await instance.delete(`/shop/carts/${SKU}/delete`);
        return response.data;
    }catch(error){
        throw error;
    }
});

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        cart: {},
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
      .addCase(CartList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(CartList.fulfilled, (state, action) => {
        state.status = 'success';
        state.cart = action.payload;
      })
      .addCase(CartList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      .addCase(UpdateCart.fulfilled, (state, action) => {
        state.status = 'Cart Updated Successfully';
        state.error = action.error;
      })
      .addCase(AddToCart.fulfilled, (state, action) => {
        state.status = 'Cart Added Successfully';
        state.error = action.error;
      })
      .addCase(DeleteCart.fulfilled, (state, action) => {
        state.status = 'Cart Deleted Successfully';
        state.error = action.error;
      })
    }
})

export default cartSlice.reducer;