import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { instance } from '../../utils/ApiRequest';
import NoResult from './NoResult';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(77,77,77,0.6)',
        zIndex: '9000'
    }
}

Modal.setAppElement( 'body' );
function InfoOne ( props ) {
    const { product,VariantDetails, IsUser } = props;
    const [rating, setrating] = useState(0);
    const [ReviewsList, setReviewsList] = useState([]);
    const [message, setMessage] = useState('');
    const [hoverRating, setHoverRating] = useState(0);
    const [ open, setOpen ] = useState( false );
    let timer;

    useEffect( () => {
        return () => {
            if ( timer ) clearTimeout( timer );
        }
    } );

    function closeModal () {
        document.getElementById( "login-modal" ).classList.remove( "ReactModal__Content--after-open" );

        if ( document.querySelector( ".ReactModal__Overlay" ) ) {
            document.querySelector( ".ReactModal__Overlay" ).style.opacity = '0';
        }

        timer = setTimeout( () => {
            setOpen( false );
        }, 350 );
    }

    function openModal ( e ) {
        e.preventDefault();
        setOpen( true );
    }

    const id = VariantDetails.ProductId;

    const setRating = ( num ) => {
        setrating(num);
        document.getElementById('rating').value = num;
    }

    // display time to min, hour, days
    function formatTimeDifference(dateString) {
        const givenDate = new Date(dateString);
        const currentDate = new Date();
        const timeDifference = Math.abs(currentDate.getTime() - givenDate.getTime()) / 1000; // Difference in seconds
    
        const intervals = [
            { label: 'year', seconds: 31536000 },
            { label: 'month', seconds: 2592000 },
            { label: 'day', seconds: 86400 },
            { label: 'hour', seconds: 3600 },
            { label: 'min', seconds: 60 },
            { label: 'sec', seconds: 1 }
        ];
    
        for (let i = 0; i < intervals.length; i++) {
            const interval = intervals[i];
            const value = Math.floor(timeDifference / interval.seconds);
            if (value >= 1) {
                return value + ' ' + (value === 1 ? interval.label : interval.label + 's') + ' ago';
            }
        }
        return 'just now';
    }
    

    const SubmitReview = async(e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            ProductId: id,
            Rating: rating,
            Review: formData.get('reply-comment')
        }
        await instance.post('/shop/reviews/add', data)
        .then(res => {
            setMessage(res.data.Data.Message);
            closeModal();
            formData.set('reply-comment', '');
            FetchReviews();
        })
        .catch(err => {
            setMessage(err.response.data.Message);
        })
        setTimeout(() => {
            setMessage('');
        }, 3000);
    }

    const FetchReviews = async() => {
        await instance.get(`/shop/reviews/list/${id}`)
        .then(res => {
            setReviewsList(res.data.Data.Reviews);
        })
        .catch(err => {
        })
    }
    const tabChange = (val) => {
        if(val === 'reviews'){
            FetchReviews();
        }
    }

    const handleMouseEnter = (value) => {
        setHoverRating(value);
    };

    const handleMouseLeave = () => {
        setHoverRating(0);
    };

    const renderStars = () => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <i
                key={i}
                className={`bi bi-star${i <= (hoverRating || rating) ? '-fill' : ''}`}
                style={{ fontSize: '24px', color: '#0051bb', cursor: 'pointer' }}
                onClick={() => setRating(i)}
                onMouseEnter={() => handleMouseEnter(i)}
                onMouseLeave={handleMouseLeave}
                />
            );
        }
        return stars;
    };

    if ( !product ) {
        return <div></div>
    }

    return (
        <Tabs selectedTabClassName="show" selectedTabPanelClassName="active show">
            <div className="product-details-tab">
                <TabList className="nav nav-pills justify-content-center">
                    <Tab className="nav-item" onClick={() => tabChange('info')}>
                        <span className="nav-link"> Additional information</span>
                    </Tab>

                    <Tab className="nav-item" onClick={() => tabChange('reviews')}>
                        <span className="nav-link" >Reviews ({ product.TotalRatings })</span>
                    </Tab>
                </TabList>

                <div className="tab-content">
                    <TabPanel className="tab-pane">
                        <div className="product-desc-content">
                            <h3>Description</h3>
                            <p>{product.Description}</p>

                            <h3 className="pt-2">Product details</h3>
                            <ul>
                                <li>Size: {VariantDetails.Size === "SM" ? "Small" : VariantDetails.Size === "MD" ? "Medium" : VariantDetails.Size === "RG" ? "Regular" : ""}</li>
                                <li>Color: {VariantDetails.Color.Name}</li>
                                <li>Material: {VariantDetails.Material.Name}</li>
                                <li>Weight: {VariantDetails.Weight}gms </li>
                                <li>Height:{VariantDetails.Dimensions.Height}mm,  Width:{VariantDetails.Dimensions.Width}mm,  Length:{VariantDetails.Dimensions.Length}mm</li>
                                <li>Model: {product.Name} </li>
                                <li>Country of region: India </li>
                            </ul>
                        </div>
                    </TabPanel>

                    <TabPanel className="tab-pane">
                        <div className="reviews">
                            <div className='review_btn'>
                                <h3>Reviews ({product.TotalRatings})</h3>
                                <span className='gradient_btn' onClick={openModal}>Add review</span>
                            </div>
                            {
                                ReviewsList.length > 0 ? 
                                    ReviewsList.map((item, index) => (
                                        <div className="review" key={index}>
                                            <div className="row no-gutters">
                                                <div className="col-auto">
                                                    <h4><Link href="#">{item.DisplayName}</Link></h4>

                                                    <div className="ratings-container">
                                                        <div className="ratings">
                                                            <div className="ratings-val" style={ { width: item.Rating * 20 + '%' } }></div>
                                                            <span className="tooltip-text">{ item.Rating }</span>
                                                        </div>
                                                    </div>
                                                    <span className="review-date mb-1" data-toggle="tooltip" data-placement="top" title={item.createdAt}>
                                                        {formatTimeDifference(item.createdAt)}
                                                    </span>
                                                </div>
                                                <div className="col">
                                                    <h4>{item.Title}</h4>
                                                    <div className="review-content">
                                                        <p>{item.Review}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                :
                                <span className='no-reviews'>No reviews found</span>
                            }
                        </div>
                        {
                            open ?
                            <Modal
                                isOpen={ open }
                                style={ customStyles }
                                className="modal-dialog"
                                overlayClassName="d-flex align-items-center justify-content-center"
                                id="login-modal"
                                onRequestClose={ closeModal }
                                shouldCloseOnOverlayClick={false}
                                closeTimeoutMS={ 10 }
                            >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h3 className="title title-simple text-left text-normal">Add a Review</h3>
                                    <button type="button" className="close" onClick={ closeModal }>
                                        <span aria-hidden="true"><i className="icon-close"></i></span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {
                                        IsUser && IsUser.User !== null ?
                                        <div className="reply">
                                            <div className="rating-form">
                                                <label htmlFor="rating" className="text-dark">Your rating * </label>
                                                <span className="rating-stars selected">
                                                    {renderStars()}
                                                </span>
                                                <select name="rating" id="rating"
                                                    style={ { display: 'none' } }>
                                                    <option value="">Rate…</option>
                                                    <option value="5">Perfect</option>
                                                    <option value="4">Good</option>
                                                    <option value="3">Average</option>
                                                    <option value="2">Not that bad</option>
                                                    <option value="1">Very poor</option>
                                                </select>
                                            </div>
                                            <form onSubmit={SubmitReview}>
                                                <label htmlFor='reply-comment'>Review</label>
                                                <textarea id="reply-message" cols="30" rows="6" name="reply-comment"
                                                    className="form-control mb-2" placeholder="Write your review...."
                                                ></textarea>
                                                <button type="submit" className={`gradient_btn ${rating > 0 ? '' : 'disabled'}`} disabled={rating < 1}>Submit</button>
                                            </form>
                                            <div className='message'>{message}</div>
                                        </div>
                                        :
                                        <NoResult
                                            title=""
                                            description="You must login to add a review."
                                            btnName="Login / signup" 
                                            btnUrl={process.env.REACT_APP_ACCOUNT_URL + 'login?redirectTo=' + window.location.href} 
                                            
                                        />
                                    }
                                </div>
                            </div>
                        </Modal>
                        : 
                        ''
                    }
                    </TabPanel>
                </div>
            </div>
        </Tabs>
    );
}

export default React.memo( InfoOne );