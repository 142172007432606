import React, { useState } from 'react';
import {Link, useNavigate, useParams } from 'react-router-dom';
import { hideSidebar, toKebabCase, toTitleCase } from '../../utils/ApiRequest';

function ShopSidebarOne ( props ) {
    const { toggle, meta, total } = props;
    const navigate = useNavigate();
    var { cat } = useParams();
    cat = cat === 'all-categories' ? '' : cat;
    const category = toTitleCase(cat || '');

    // Get the search parameter from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const search = searchParams.get('s');
    const [isMaterial, setIsMaterial] = useState(true);
    const [isColorEx, setIsColorEx] = useState(true);
    const [isMatViewMore, setIsMatViewMore] = useState(false);
    const [isColorViewMore, setIsColorViewMore] = useState(false);
    var mat = searchParams.get('mat');
    var col = searchParams.get('col');
    const srt = searchParams.get('sort');
    mat = mat && mat.split(',')
    col = col && col.split(',');
    
    // const [category, setCategory] = useState(cat || []);
    const [material, setMaterial] = useState(mat || []);
    const [color, setColor] = useState(col || []);

    var categories = meta && meta.Facets.Categories;
    
    categories = categories.slice().sort((a, b) => {
        let keyA = category.indexOf(a.Key);
        let keyB = category.indexOf(b.Key);
        
        if (keyA !== -1 && keyB !== -1) {
            return keyA - keyB;
        }
        if (keyA !== -1) {
            return -1;
        }
        if (keyB !== -1) {
            return 1;
        }
        return 0;
    });

    var materials = meta && meta.Facets.Materials;
    materials = materials.slice().sort((a, b) => {
        let keyA = material.indexOf(a.Key);
        let keyB = material.indexOf(b.Key);
        
        if (keyA !== -1 && keyB !== -1) {
            return keyA - keyB;
        }
        if (keyA !== -1) {
            return -1;
        }
        if (keyB !== -1) {
            return 1;
        }
        return 0;
    });
    var colors = meta && meta.Facets.Colors;
    colors = colors.slice().sort((a, b) => {
        let keyA = color.indexOf(a.Key);
        let keyB = color.indexOf(b.Key);
        
        if (keyA !== -1 && keyB !== -1) {
            return keyA - keyB;
        }
        if (keyA !== -1) {
            return -1;
        }
        if (keyB !== -1) {
            return 1;
        }
        return 0;
    });
    

    function selectedMaterial(e){
        let updatedList = [...material];
        if(e.target.checked) {
        updatedList = [...material, e.target.value];
        } else {
        updatedList.splice(material.indexOf(e.target.value), 1);
        }
        setMaterial(updatedList);
        navigate(`?s=${search || ''}&mat=${updatedList.join(",")}&col=${color.join(",")}&sort=${srt || ''}`);
    }
    function selectedColor(e){
        let updatedList = [...color];
        if(e.target.checked) {
        updatedList = [...color, e.target.value];
        } else {
        updatedList.splice(color.indexOf(e.target.value), 1);
        }
        setColor(updatedList);
        navigate(`?s=${search || ''}&mat=${material.join(",")}&col=${updatedList.join(",")}&sort=${srt || ''}`);
    }
    function ClearAll(e){
        e.preventDefault();
        setColor([]);
        setMaterial([]);
        navigate(`/`);
        hideSidebar();
    }
    function expandMaterialCollapse(){
        setIsMaterial(!isMaterial);
    }
    function expandColoreCollapse(){
        setIsColorEx(!isColorEx);
    }
    return (
        <>
            <aside className={ `${toggle ? 'sidebar-filter' : 'sidebar'} sidebar-shop` }>
                <div className="widget widget-clean">
                    <label>Filters</label>
                    <span onClick={ClearAll} className="sidebar-filter-clear" scroll={ false }>Clean All</span>
                </div>
                <div className={ toggle ? 'sidebar-filter-wrapper' : 'filter-wrapper' }>
                    {/* categories */}
                    <div className={`filter_wrap category`}>
                        <div className={`collapsible`}>
                            <span className="fliter_title" type="button">Category</span>
                        </div>
                        <div className={`content`}>
                            <Link to={'/categories/all-categories'} onClick={hideSidebar}>
                                <span className={`${category === '' ? 'active' : ''}`} >All categories ({ total })</span>
                            </Link>
                            {categories && categories.map( ( item, index ) => {
                                const cat_item = toKebabCase(item.Name);
                                return(
                                    <Link to={`/categories/${cat_item}`} onClick={hideSidebar}>
                                        <span className={`${category === item.Name ? 'active' : ''}`} htmlFor={`cat_${index}`}>{ item.Name } ({ item.Count })</span>
                                    </Link>
                                );
                            })}
                        </div>
                    </div>
                    {/* materials */}
                    <div className={`filter_wrap ${isMaterial ? 'active' : ''}`}>
                        <div className={`collapsible`} onClick={expandMaterialCollapse}>
                            <span className="fliter_title" type="button">Materials</span>
                            <span className="filter_close"></span>
                        </div>
                        <div className={`content ${!isMaterial ? 'hide' : ''}`}>
                            <form>
                                {materials && (isMatViewMore ? materials : materials.slice(0, 3)).map( ( item, index ) => {
                                    return(
                                        <label key={index}>
                                            <input type="checkbox" className="main_uni_courses" name='category' id={`mat_${index}`} checked={material.includes(item.Key)} value={item.Key} onChange={(e) => selectedMaterial(e)}/>
                                            <span htmlFor={`mat_${index}`}>{ item.Name } ({ item.Count })</span>
                                        </label>
                                    );
                                })}
                                {!isMatViewMore && materials.length > 3 && <p onClick={() => setIsMatViewMore(true)} className='view-more'>View more</p>}
                                {isMatViewMore && <p onClick={() => setIsMatViewMore(false)} className='view-more'>View less</p>}
                            </form>
                        </div>
                    </div>
                    {/* colors */}
                    <div className={`filter_wrap ${isColorEx ? 'active' : ''}`}>
                        <div className={`collapsible`} onClick={expandColoreCollapse}>
                            <span className="fliter_title" type="button">Colors</span>
                            <span className="filter_close"></span>
                        </div>
                        <div className={`content ${!isColorEx ? 'hide' : ''}`}>
                            <form>
                                {colors && (isColorViewMore ? colors : colors.slice(0, 3)).map( ( item, index ) => {
                                    return(
                                        <label key={index}>
                                            <input type="checkbox" className="main_uni_courses" name='category' id={`col_${index}`} checked={color.includes(item.Key)} value={item.Key} onChange={(e) => selectedColor(e)}/>
                                            <span htmlFor={`col_${index}`}>{ item.Name } ({ item.Count })</span>
                                        </label>
                                    );
                                })}
                                {!isColorViewMore && colors.length > 3 && <p onClick={() =>setIsColorViewMore(true)} className='view-more'>View more</p>}
                                {isColorViewMore && <p onClick={() => setIsColorViewMore(false)} className='view-more'>View less</p>}
                            </form>
                        </div>
                    </div>
                </div>
            </aside>
        </>
    );
}

export default React.memo( ShopSidebarOne );