import React, { useCallback, useEffect, useRef, useState } from 'react';
import './App.css';
import './Assets/css/fonts-molla.min.css';
import './Assets/css/chillax.css';
import './Assets/css/bootstrap-icons.min.css';
import Header from './Components/Header';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Components/Home';
import SingleProduct from './Components/Products/SingleProduct';
import Cart from './Components/shop/Cart';
import MobileMenu from './Components/features/mobile-menu';
import Wishlist from './Components/shop/Whishlist';
import Footer from './Components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { hideSidebar, instance } from './utils/ApiRequest';
import ScrollToTop from './Components/features/ScrollToTop';
import { fetchWishlistItems } from './store/WhishlistSlice';
import Loader from './Components/features/Loader';
import MaintananceRoute from './Components/Maintenance/MaintenanceRoute';
import Maintenance from './Components/Maintenance/Maintenance';
import { fetchCategorisList } from './store/Categories';

function App() {
  const scrollTop = useRef(0);
  const [toggle, setToggle] = useState(false);
  const [mob, setMob] = useState(false);
  const [IsUser, setIsUser] = useState({});
  const [isValidSession, setIsValidSession] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();

  const wishlistCount = useSelector(state => state.whishlist);
  var categories = useSelector(state => state.categories);
  categories = categories && categories.categories.Data;

  useEffect( () => {
    window.addEventListener( 'scroll', scrollHandler, false );
    scrollTop.current = document.querySelector( '#scroll-top' );
    window.addEventListener("resize", resizeHandle);
    resizeHandle();
    return () => {
      window.removeEventListener("resize", resizeHandle);
    };
}, [] )
//scroll top 
function toScrollTop () {
  window.scrollTo( {
      top: 0,
      behavior: 'smooth'
  } );
}
function scrollHandler () {
  if ( window.scrollY >= 400 ) {
      scrollTop.current.classList.add( 'show' );
  } else {
      scrollTop.current.classList.remove( 'show' );
  }
}
//end

 //validate session cookie
 const validateSessionCookie = useCallback(() =>{
  instance.get('/account/users/validate-session')
  .then(res => {
    if(res.data){
      setIsUser(res.data.Data);
      dispatch(fetchCategorisList());
      if(res.data.Data.User !== null){
        localStorage.setItem('user', true);
        dispatch(fetchWishlistItems());
      }else{
        localStorage.removeItem('user');
      }
    }
    setIsValidSession(true);
  }).catch(err => {
    instance.post('/account/users/create-session')
    .then(res => {
      if(res.data){
        validateSessionCookie();
      }
    }).catch(err => {
      alert("Something went wrong");
      window.location.reload();
    })
      localStorage.removeItem('user');
  })
},[dispatch])

// maintenance mode
const MaintananceMode = useCallback(() => {
  instance.get('/shop/maintenance')
  .then(res => {
    if(res.status === 200){
      setIsMaintenance(res.data.Data);
    // validateSessionCookie();
      setIsValidSession(true);
    }
  })
  .catch(err => {
    validateSessionCookie();
  })
},[validateSessionCookie])

useEffect(() => {
  MaintananceMode();
}, [MaintananceMode])
//sidebar for mobile view
function resizeHandle() {
  if (document.querySelector("body").offsetWidth < 992) setToggle(true);
  else setToggle(false);
  if (document.querySelector("body").offsetWidth < 992) setMob(true);
  else setMob(false);
}
function toggleSidebar() {
  if (
    document.querySelector("body").classList.contains("sidebar-filter-active")
  ) {
    document.querySelector("body").classList.remove("sidebar-filter-active");
  } else {
    document.querySelector("body").classList.add("sidebar-filter-active");
  }
}

function toggleSortBar(){
  if (
    document.querySelector("body").classList.contains("sort-filter-active")
  ) {
    document.querySelector("body").classList.remove("sort-filter-active");
  } else {
    document.querySelector("body").classList.add("sort-filter-active");
  }
}

function hideMobileMenu () {
  document.querySelector( 'body' ).classList.remove( 'mmenu-active' );
}
//end mobile side bar

  return (
    <div className='site'>
    { isValidSession && categories ? 
    <>
      {isMaintenance == null && <Header IsUser={IsUser}/>}
      <div className='app-body'>
        <Routes>
          <Route path='/maintenance' element={<Maintenance isMaintenance={isMaintenance} />} />
          <Route element={<MaintananceRoute isMaintenance={isMaintenance} />}>
            <Route path='/' element={<Navigate to='/categories/all-categories' />} />
            <Route path="/product/:SKU" element={<SingleProduct IsUser={IsUser} />} />
            <Route path="/categories/:cat" element={<Home toggle={toggle} categories={categories} />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/wishlist" element={<Wishlist IsUser={IsUser} />} />
            <Route path="*" element={<Navigate to='/categories/all-categories' />} />
          </Route>
        </Routes>
      </div>
      {isMaintenance == null && <MobileMenu />}
      {isMaintenance == null && <Footer />}
      <ScrollToTop />
    </>
    : <Loader /> }
      <div id='loader'>
        <Loader />
      </div>
        <div className="mobile-menu-overlay" onClick={ hideMobileMenu }></div>
        <button id="scroll-top" title="Back to top" onClick={ toScrollTop }>
            <i className="icon-arrow-up"></i>
        </button>
        <div
          className="sidebar-filter-overlay"
          onClick={hideSidebar}
        ></div>
        {
          mob &&
          <div className={!location.pathname.includes('/categories') ? "footer-tik remove_bottom" :"footer-tik"}>
            <ul>
              <li>
                <Link to={process.env.REACT_APP_HOME_URL} className="wishlist-link">
                  <div className="icon">
                    <i className="icon-home"></i>
                  </div>
                  <span>Home</span>
                </Link>
              </li>
              {location.pathname.includes('/categories/') ? 
              <li>
                  <div className='icon' onClick={toggleSortBar}>
                    <i className="fa-solid fa-arrow-down-short-wide"></i>
                  </div>
                  <span>sort</span>
              </li> : ''}
              {location.pathname.includes('/categories/') ? 
              <li>
                <div className='icon' onClick={toggleSidebar}>
                  <i className="fa-solid fa-filter"></i>
                </div>
                <span>filter</span>
              </li> : ''}
              <li className='wishlist'>
                <Link to='/wishlist' className="wishlist-link">
                  <div className="icon">
                      <i className="icon-heart-o"></i>
                      <span>wishlist</span>
                      <span className="wishlist-count badge">{ (IsUser && IsUser.User !== null && wishlistCount.whishlist.Data && wishlistCount.whishlist.Data.length) || "0"}</span>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        }
    </div>
  );
}

export default App;
