import React, { useState, useEffect } from "react";
import StickyBox from "react-sticky-box";
import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import ShopListOne from "./shop/ShopListOne";
import ShopSidebarOne from "./shop/ShopSidebarOne";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsList } from "../store/Products";
import HeaderSearch from "./header/HeaderSearch";
import useSize from "./features/useSize";
import { instance, toTitleCase } from "../utils/ApiRequest";
import SEO from "./SEO/SEO";
import PageHeader from "./features/PageHeader";
import Loader from "./features/Loader";

function ShopGrid({toggle, categories}) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(window.location.search);
  const search = searchParams.get('s');
  const mat = searchParams.get('mat');
  const color = searchParams.get('col');
  const srt = searchParams.get('sort');
  const [type, setType] = useState('4cols');
  const [firstLoading, setFirstLoading] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [sort, setSort] = useState(srt || "BestSelling");
  const [MoreProducts, setMoreProducts] = useState([]);
  const [UpdatedMeta, setUpdatedMeta] = useState(null);
  // category
  var { cat } = useParams();
  cat = cat === 'all-categories' || cat === undefined ? '' : cat;
  var category_name = toTitleCase(cat || '');

  const width = useSize();
  const isFiltered = (search || cat || color || mat || srt) ? '&' : '';
  
  const dispatch = useDispatch();
  var productsData = useSelector(state => state.products.products);
  // products list
  productsData = productsData.Data;
  // total count
  const totalCount = MoreProducts.length > 0 ? MoreProducts.length : productsData && productsData.Rows.length;
  // selected category object
  var category_obj = category_name ? categories && categories.find(item => item.Name === category_name) : '';
  var category = category_obj ? category_obj.Key : '';
  
  var filter_query = '';
  // filter query
  filter_query = `${search ? `search=${search}`: ''}${(category || color || mat) ? `&filters=${category ? `Category in ${category}`:'' }${color ? ` and Color in ${color}` : '' }${mat ? ` and Material in ${mat}`: ''}` : ''}${srt ? `&sort=${srt}`: ''}${isFiltered}limit=${type === "list" ? "20" : "40"}`;
  useEffect(() => {
    // if no category redirect to all categories
    if(category_obj === undefined){
      navigate('/categories/all-categories');
    }
    dispatch(fetchProductsList(filter_query));
    setFirstLoading(true);
    setMoreProducts([]);
    setUpdatedMeta(null);
  }, [location, dispatch, filter_query, category_obj, navigate]);

  function onSortByChange(e) {
    setSort(e.target.value);
    navigate(`?s=${search || ''}&cat=${cat ? cat : ''}&mat=${mat ? mat : ''}&col=${color ? color : ''}&sort=${e.target.value || ''}`);
  }
  
  function handleTypeChange(val){
    setType(val);
    if (val === "list") {
      setPerPage(5);
    } else if (val === "2cols") {
      setPerPage(6);
    } else if (val === "3cols") {
      setPerPage(9);
    } else if (val === "4cols") {
      setPerPage(12);
    }
  }

  const LoadMore = (skipUrl) => {
    const quertString = skipUrl.split('?')[1];
    const NextPageLink = new URLSearchParams(quertString);
    const skipToken = NextPageLink.get('skipToken');

    if(skipToken !== ''){
      instance.get(`/shop/products/list/v2/?skipToken=${skipToken}`)
      .then(res => {
        const newDataArray = productsData.Rows.concat(res.data.Data.Rows);
        setUpdatedMeta(res.data.Data.Meta);
        if(MoreProducts.length > 0){
          setMoreProducts(prevProducts => [...prevProducts, ...res.data.Data.Rows]);
        }else{
          setMoreProducts(newDataArray);
        }
      })
      .catch(err => {
      })
    }
  }

  return (
    <main className="main shop">
      <SEO 
        title={`Ocean 3D Shop - ${category_name ? category_name : 'OCEAN 3D: Sail Beyond Limits with Our 3D-Printed Collection | shop.ocean3d.in'}`}
        description='Discover a diverse range of high-quality 3D oceanic products at Ocean3D Shop. 
        Explore marine life models, ocean-themed decor, and more. 
        Shop now and bring the ocean into your home!'
        name='Ocean3dShop'
        type='article'
        link= {window.location.href}
      />
      <PageHeader title={category_name || "All Categories"} subtitle="" />
      <div className="page-content container mt-20">
        {productsData ?
          <div className="row skeleton-body">
            <div className={`col-lg-9 skel-shop-products loaded`}>
              <div className="toolbox">
                {
                  width < 992 && 
                  <div className="mobile-search">
                    <HeaderSearch />
                  </div>
                }
                <div className="toolbox-left">
                  {
                    search ? 
                    <div className="toolbox-info">
                      <span>Showing results for <strong>{search}</strong></span>
                    </div>
                    :
                    productsData && productsData.Rows ? (
                      <div className="toolbox-info">
                        <span> Found {totalCount} items</span>
                      </div>
                    ) : (
                      ""
                    )
                  }
                </div>
                <div className={`sort-options-container`}>
                  <div className={`sort-options`}>
                  <label htmlFor="sortby">Sort by:</label>
                    <div className="sort-option">
                      <input
                        type="radio"
                        id="Newest"
                        name="sortOption"
                        value="Newest"
                        checked={sort === "Newest"}
                        onChange={onSortByChange}
                      />
                      <label htmlFor="Newest">Latest</label>
                    </div>
                    <div className="sort-option">
                      <input
                        type="radio"
                        id="PriceLowToHigh"
                        name="sortOption"
                        value="PriceLowToHigh"
                        checked={sort === "PriceLowToHigh"}
                        onChange={onSortByChange}
                      />
                      <label htmlFor="PriceLowToHigh">Price: Low to High</label>
                    </div>
                    <div className="sort-option">
                      <input
                        type="radio"
                        id="PriceHighToLow"
                        name="sortOption"
                        value="PriceHighToLow"
                        checked={sort === "PriceHighToLow"}
                        onChange={onSortByChange}
                      />
                      <label htmlFor="PriceHighToLow">Price: High to Low</label>
                    </div>
                    <div className="sort-option">
                      <input
                        type="radio"
                        id="BestSelling"
                        name="sortOption"
                        value="BestSelling"
                        checked={sort === "BestSelling"}
                        onChange={onSortByChange}
                      />
                      <label htmlFor="BestSelling">Best Selling</label>
                    </div>
                    <div className="sort-option">
                      <input
                        type="radio"
                        id="TopRated"
                        name="sortOption"
                        value="TopRated"
                        checked={sort === "TopRated"}
                        onChange={onSortByChange}
                      />
                      <label htmlFor="TopRated">Top Rated</label>
                    </div>
                  </div>
                </div>


                <div className="toolbox-right">
                  <div className="toolbox-sort">
                    <label htmlFor="sortby">Sort by:</label>
                    <div className="select-custom">
                      <select
                        name="sortby"
                        id="sortby"
                        className="form-control"
                        onChange={onSortByChange}
                        value={sort}
                      >
                        <option value="Newest">Latest</option>
                        <option value="PriceLowToHigh">Price: Low to High</option>
                        <option value="PriceHighToLow">Price: High to Low</option>
                        <option value="BestSelling">Best Selling</option>
                        <option value="TopRated">Top Rated</option>
                      </select>
                    </div>
                  </div>
                  <div className="toolbox-layout">
                    <Link
                      href="/"
                      className={`btn-layout ${type === "list" ? "active" : ""}`}
                      scroll={false}
                      onClick={() => handleTypeChange("list")}
                    >
                      <svg width="16" height="10">
                        <rect x="0" y="0" width="4" height="4" />
                        <rect x="6" y="0" width="10" height="4" />
                        <rect x="0" y="6" width="4" height="4" />
                        <rect x="6" y="6" width="10" height="4" />
                      </svg>
                    </Link>

                    <Link
                      href="/"
                      className={`btn-layout ${
                        type === "4cols" ? "active" : ""
                      }`}
                      scroll={false}
                      onClick={() => handleTypeChange("4cols")}
                    >
                      <svg width="10" height="10">
                        <rect x="0" y="0" width="4" height="4" />
                        <rect x="6" y="0" width="4" height="4" />
                        <rect x="0" y="6" width="4" height="4" />
                        <rect x="6" y="6" width="4" height="4" />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>

              <ShopListOne
                products={MoreProducts.length > 0 ? MoreProducts : productsData.Rows}
                perPage={perPage}
                loading={firstLoading}
                type={type}
                toggle={toggle}
              ></ShopListOne>
              {
                // Display "Load more" button initially if productsData.Meta.NextPageLink is not null
                productsData.Meta.NextPageLink !== null && UpdatedMeta == null && (
                  <div className="text-center" onClick={() => LoadMore(productsData.Meta.NextPageLink)}>
                    <span className="btn btn-primary">Load more</span>
                  </div>
                )
              }

              {
                // Display "Load more" button after initial load if nextPage is not empty
                UpdatedMeta !== null && UpdatedMeta.NextPageLink && (
                  <div className="text-center" onClick={() => LoadMore(UpdatedMeta.NextPageLink)}>
                    <span className="btn btn-primary">Load more</span>
                  </div>
                )
              }
            </div>

            <aside
              className={`col-lg-3 skel-shop-sidebar order-lg-first skeleton-body ${
               firstLoading ? "loaded" : ""
              }`}
            >
              <div className="skel-widget"></div>
              <div className="skel-widget"></div>
              <div className="skel-widget"></div>
              <div className="skel-widget"></div>
              <StickyBox className="sticky-content" offsetTop={100}>
                <ShopSidebarOne 
                toggle={toggle} 
                meta={UpdatedMeta == null ? productsData.Meta : UpdatedMeta}
                total={totalCount}
                ></ShopSidebarOne>
              </StickyBox>
              
            </aside>
          </div>
         : <Loader />}
      </div>
    </main>
  );
}

export default ShopGrid;
