import React, { useRef } from 'react';
import StickyHeader from './features/StickyHeader';
import { Link } from 'react-router-dom';
import WishlistMenu from './header/WishlistMenu';
import CartMenu from './header/CartMenu';
import { useSelector } from 'react-redux';
import TopHeader from './header/TopHeader';
import MainMenu from './features/main-menu';

function Header ({IsUser}) {
    const ref = useRef(null);
    const wishlistCount = useSelector(state => state.whishlist);
    const headerHight = document.getElementsByClassName("header-top");
    const top = headerHight.length > 1 ?  headerHight[0].offsetHeight : 150;

    function openMobileMenu () {
        document.querySelector( 'body' ).classList.add( 'mmenu-active' );
    }

    return (
        <header className="header header-14">
            <div className="header-top bg-body-tertiary">
                <TopHeader />
            </div>
            <StickyHeader top={top}>
                <div ref={ref} className="header-middle sticky-header">
                    <div className='container'>
                        <div className="row py-2">
                            <div className="col-auto col-lg-3 col-xl-3 col-xxl-2">
                                <button className="mobile-menu-toggler" onClick={ openMobileMenu }>
                                    <span className="sr-only">Toggle mobile menu</span>
                                    <i className="icon-bars"></i>
                                </button>

                                <Link to={process.env.REACT_APP_HOME_URL} className="logo">
                                    <img src={"https://cdn.ocean3d.in/global/logo-white.png"} alt="ocean-logo"/>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
                                </Link>
                            </div>
                            <div className="col col-lg-9 col-xl-9 col-xxl-10 header-middle-right">
                                <div className="row">
                                    <div className="col-lg-9 col-xxl-4-5 col d-none d-lg-block justify-content-end align-items-center">
                                        <MainMenu />
                                    </div>
                                    <div className="col-lg-3 col-xxl-5col d-flex justify-content-end align-items-center">
                                        <div className="header-dropdown-link">
                                            <WishlistMenu wishlistCount={IsUser && IsUser.User !== null && wishlistCount.whishlist.Data && wishlistCount.whishlist.Data.length}/>
                                            <CartMenu/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
            </StickyHeader>
        </header>
    )
}

export default Header;