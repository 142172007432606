import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { DeleteWishListItem, fetchWishlistItems } from '../../store/WhishlistSlice';
import { AddToCart } from '../../store/CartSlice';
import useSize from '../features/useSize';
import SEO from '../SEO/SEO';
import PageHeader from '../features/PageHeader';

function Wishlist ({IsUser}) {
    const size = useSize();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const wishlistItems = useSelector(state => state.whishlist);
    // cart items
    var CartData = useSelector(state => state.cartState);
    const CartLists = CartData.status === "success" && CartData.cart.CartItems;

    useEffect(() => {
        if(IsUser && IsUser.User !== null){
            dispatch(fetchWishlistItems());
        }
    },[IsUser, dispatch])

    // check product is in cart
    
    function onCartClick ( e, index = 0, SKU ) {
        const isItemInCart = CartLists && CartLists.some(item => item.SKU === SKU);
        e.preventDefault();
        if ( e.currentTarget.classList.contains( 'btn-disabled' ) ) return;
        let newProduct = {
            "SKU" : SKU,
            "Quantity" : 1
        };
        if(isItemInCart){
            navigate('/cart');
        }else{
            dispatch(AddToCart(newProduct));
        }
        setTimeout( () => {
            navigate('/cart');
        }, 250 );
    }

    function removeFromWishList(SKU){
        dispatch(DeleteWishListItem(SKU));
        setTimeout(() => {
            dispatch(fetchWishlistItems())
        }, 600);
    }
    return (
        <main className="main">
            <SEO 
                title='Your Ocean 3D Shop Wishlist - Save Your Favorite 3D Oceanic Products'
                description='Explore and manage your Ocean3D Shop wishlist for high-quality 3D oceanic products. 
                            Save your favorite items and plan your next purchase. Start building your dream collection today!'
                name='Ocean3dShop'
                type='article'
                link= {window.location.href}
            />
             <PageHeader title={"Wishlist"} subtitle="" />
            <div className="page-content wishlist pb-5">
                {
                    IsUser && IsUser.User !== null ?
                    wishlistItems.whishlist.Status === 'Success' ?
                    wishlistItems.whishlist.Data.length > 0 ?
                        <div className="container">
                            {
                                size < 768 ?
                                <div className='mobile_table_view'>
                                    {
                                    wishlistItems.whishlist.Data.map( ( product, index ) => {
                                            let size = product.Size === "SM" ? "Small" : product.Size === "MD" ? "Medium" : product.Size === "RG" ? "Regular" : "";
                                            const isItemInCart = CartLists && CartLists.some(item => item.SKU === product.SKU);
                                            return(
                                                <div className='item' key={index}>
                                                    <figure className="product-media-loaded">
                                                        <Link to={ `/product/${product.SKU}` } className="product-image">
                                                            <img src={product.Images[0].URL } alt = { product.Name } />
                                                        </Link>
                                                    </figure>
                                                    <div className='product-details'>
                                                        <h4 className="product-title">
                                                            <Link to={ `/product/${product.SKU}` }>{ product.Name }</Link>
                                                        </h4>
                                                        <span className='mat-col'>
                                                            {product.Material.Name} - {product.Color.Name} 
                                                        </span>
                                                        <span className='addional'>
                                                            <span className='mat-col'><span className='strong'>Size:</span> {size}</span>
                                                            <span className="out-price">₹<del>{ product.Price }</del>{" "}{product.SalePrice}</span>
                                                        </span>
                                                        <div className='actions'>
                                                            <div className="dropdown">
                                                                <Link to="#" onClick={ e => onCartClick( e, 0, product.SKU ) } className="gradient_btn btn-select">
                                                                    <i className="icon-shopping-cart"></i>
                                                                    {isItemInCart ? "go to cart" : "add to cart"}
                                                                </Link>
                                                            </div>
                                                            <button
                                                                className="btn-remove"
                                                                onClick={() => removeFromWishList( product.SKU )}
                                                            >
                                                                <i className="icon-close"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                    }) 
                                    }
                                </div>
                                :
                                <table className="table table-wishlist table-mobile">
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>Sze</th>
                                            <th>Material</th>
                                            <th>Color</th>
                                            <th>Price</th>
                                            <th>Action</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            wishlistItems.whishlist.Data.map( ( product, index ) => {
                                                let size = product.Size === "SM" ? "Small" : product.Size === "MD" ? "Medium" : product.Size === "RG" ? "Regular" : "";
                                                const isItemInCart = CartLists && CartLists.some(item => item.SKU === product.SKU);
                                                return(
                                                <tr
                                                    key={ index }
                                                >
                                                    <td className="product-col">
                                                        <div className="product">
                                                            <figure className="product-media">
                                                                <Link to={ `/product/${product.SKU}` } className="product-image">
                                                                    <img src={product.Images[0].URL } alt = { product.Name } />
                                                                </Link>
                                                            </figure>
                                                            <div className='product-disc'>
                                                                <h4 className="product-title">
                                                                    <Link to={ `/product/${product.SKU}` }>{ product.Name }</Link>
                                                                </h4>
                                                                <span data-toggle="tooltip" data-placement="top" title={product.Description} >
                                                                    {product.Description.substr(0, 40)}{product.Description.length > 40 && "...."}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="price-col">
                                                        {size}
                                                    </td>
                                                    <td className="price-col">
                                                        {product.Material.Name}
                                                    </td><td className="price-col">
                                                        {product.Color.Name}
                                                    </td>
                                                    <td className="price-col">
                                                        <div className="product-price d-inline-block mb-0">
                                                            <span className="out-price">₹<del>{ product.Price }</del>{" "}{product.SalePrice}</span>
                                                        </div>
                                                    </td>
                                                    <td className="action-col">
                                                        <div className="dropdown">
                                                            <Link to="#" onClick={ e => onCartClick( e, 0, product.SKU ) } className="gradient_btn btn-select">
                                                                <i className="icon-shopping-cart"></i>
                                                                {isItemInCart ? "Go to Cart" : "Add to Cart"}
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td className="remove-col">
                                                        <button
                                                            className="btn-remove"
                                                            onClick={() => removeFromWishList( product.SKU )}
                                                        >
                                                            <i className="icon-close"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )} )
                                        }
                                    </tbody>
                                </table>
                            }

                            <div className="wishlist-share">
                                <div className="social-icons social-icons-sm mb-2">
                                    <label className="social-label">Follow us on:</label>
                                    <Link to="https://www.facebook.com/ocean3d.in" className="social-icon" title="Facebook"><i className="icon-facebook-f"></i></Link>
                                    <Link to="https://www.youtube.com/@ocean3dindia" className="social-icon" title="Instagram"><i className="icon-instagram"></i></Link>
                                    <Link to="https://x.com/ocean3dindia" className="social-icon" title="Twitter"><i className="fa-brands fa-x-twitter"></i></Link>
                                    <Link to="https://www.instagram.com/ocean3d.in" className="social-icon" title="Youtube"><i className="icon-youtube"></i></Link>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="container">
                            <div className="text-center">
                                <i className="icon-heart-o wishlist-empty d-block" style={ { fontSize: '15rem', lineHeight: '1' } }></i>
                                <span className="d-block mt-2 no_pro_wishlist">No products added to wishlist</span>
                                <Link
                                    to="/"
                                    className="gradient_btn mt-2"
                                >Continue Shop</Link>
                            </div>
                        </div>
                        : 
                        <div>Loading</div>
                        :
                        <div className='text-center'>
                            <i className="icon-heart-o wishlist-empty d-block" style={ { fontSize: '15rem', lineHeight: '1' } }></i>
                            <h3>Please Login to use Wishlist</h3>
                            <div className='mt-2 login_actions'>
                                <a href={`${process.env.REACT_APP_ACCOUNT_URL}login?redirectTo='${window.location.href}`} className="gradient_btn">Login</a>
                                &nbsp; &nbsp; &nbsp;
                                <a href={`${process.env.REACT_APP_ACCOUNT_URL}register?redirectTo='${window.location.href}`} className="gradient_btn">Signup</a>
                            </div>
                        </div>
                }

            </div>
        </main>
    )
}

export default Wishlist;