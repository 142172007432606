import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function MainMenu() {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const search = searchParams.get('s');
    const cat = searchParams.get('cat');
    var mat = searchParams.get('mat');
    var col = searchParams.get('col');
    const srt = searchParams.get('sort');
    mat = mat && mat.split(',');
    col = col && col.split(',');
    const [ searchTerm, setSearchTerm ] = useState( search );

    function onSearchChange ( e ) {
        setSearchTerm( e.target.value );
    }
    function onSubmitSearchForm ( e ) {
        e.preventDefault();
        navigate(`?s=${searchTerm || ''}&cat=${cat ? cat.join(",") : ''}&mat=${mat ? mat.join(",") : ''}&col=${col ? col.join(",") : ''}&sort=${srt || ''}`);
    }
    function ClearSearch(){
        if(search){
            navigate(`?cat=${cat ? cat.join(",") : ''}&mat=${mat ? mat.join(",") : ''}&col=${col ? col.join(",") : ''}&sort=${srt || ''}`);
        }
        setSearchTerm("");
    }

    return (
        <nav className="main-nav">
            <ul className="menu sf-arrows">
                <li>
                    <a href={process.env.REACT_APP_HOME_URL} className="sf-with-ul">Home</a>
                </li>
                <li className='active'>
                    <a href={`${process.env.REACT_APP_SHOP_URL}`} className="sf-with-ul" scroll={ false }>Shop</a>
                </li>
                <li>
                    <a href={`${process.env.REACT_APP_PRINT_URL}`} className="sf-with-ul">Print</a>
                </li>
                <li>
                    <a href={`${process.env.REACT_APP_SUPPORT_URL}`} className="sf-with-ul">Support</a>
                </li>
                <li className='header-search header-search-extended header-search-visible header-search-no-radius d-none d-lg-block'>
                    <form onSubmit={ onSubmitSearchForm }>
                        <div className="header-search-wrapper search-wrapper-wide">
                            <label htmlFor="q" className="sr-only" value={ searchTerm }></label>
                            <input 
                                type="text" 
                                onChange={ onSearchChange } 
                                value={ searchTerm } 
                                className="form-control" 
                                name="q" 
                                placeholder="Search product ..." 
                            />
                            <button className='d-none' type="submit">Submit</button>
                            <button className="close-icon" type="reset" style={{display: searchTerm ? "block" : "none"}} onClick={ClearSearch}></button>
                            <i className="bi bi-search icon-placeholder"></i>
                        </div>
                    </form>
                </li>
            </ul>
        </nav>
    );
}

export default MainMenu;