import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../utils/ApiRequest";

const cat_endpoint = '/shop/categories/list';
export const fetchCategorisList = createAsyncThunk("categories/fetchCategorisList", async () => {
    try{
        const response = await instance.get(cat_endpoint);
        return response.data;
    }catch(error){
        throw error;

    }
  });

const CategoriesSlice = createSlice({
    name: 'categories',
    initialState:{
        categories: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
      .addCase(fetchCategorisList.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategorisList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategorisList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    }
})

export default CategoriesSlice.reducer;
