import React, { useEffect } from 'react';
import './Maintenance.css';
import { useNavigate } from 'react-router-dom';

function Maintenance({isMaintenance}) {
    const navigate = useNavigate();

    useEffect(() => {
        if(isMaintenance == null){
            navigate('/');
        }
    },[isMaintenance, navigate])
  return (
    isMaintenance &&
    <div className="maintenance">
        <div className="maintenance_contain">
            <img src={isMaintenance.ImageURL} alt="maintenance" />
            <span className="pp-infobox-title-prefix">{isMaintenance.Caption}</span>
            <div className="pp-infobox-title-wrapper">
                <h3 className="pp-infobox-title">{isMaintenance.Title}</h3>
            </div> 
            <div className="pp-infobox-description">
                <p>{isMaintenance.Subtitle}</p>
            </div>    
            <span className="title-text pp-primary-title">We are social</span>
            <div className="pp-social-icons pp-social-icons-center pp-responsive-center">
                <span className="pp-social-icon">
                    <a href="https://www.facebook.com/ocean3d.in" rel="noreferrer" target="_blank" title="Facebook" aria-label="Facebook" role="button">
                        <i className="icon-facebook-f"></i>
                    </a>
                </span>
                <span className="pp-social-icon">
                    <a href="https://www.youtube.com/@ocean3dindia" rel="noreferrer" target="_blank" title="Youtube" aria-label="Youtube" role="button">
                        <i className="icon-youtube"></i>
                    </a>
                </span>
                <span className="pp-social-icon">
                    <a href="https://www.instagram.com/ocean3d.in" rel="noreferrer" target="_blank" title="Instagram" aria-label="Instagram" role="button">
                        <i className="icon-instagram"></i>
                    </a>
                </span>
            </div>
        </div>
  </div>
  )
}

export default Maintenance