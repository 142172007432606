import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate } from 'react-router-dom';

function ProductListView ( props ) {
    const { product, toggle} = props;
    const navigate = useNavigate();
    const ProductClick = (Variant) => {
        if(!toggle){
            window.open("/product/"+Variant, "_blank", "noreferrer");
        }else{
            navigate(`/product/${Variant}`);
        }
    }
    return (
        <div className="product product-list">
            <div className="row pr-2" onClick={() => ProductClick(product.Variant)}>
                <div className="col-lg-3 col-md-3 col-6">
                    <figure className="product-media-loaded">
                        <Link to={ `/product/${product.Variant}` }>
                            <LazyLoadImage
                                alt={product.Image.Alt}
                                src={ product.Image.ThumbnailURL }
                                threshold={ 500 }
                                effect="black and white"
                                wrapperClassName="product-image"
                            />
                        </Link>
                    </figure>
                </div>
                <div className="col-md-6 order-last">
                    <div className="product-body product-action-inner">
                        <h3 className="product-title">
                            <Link to={ `/product/${product.Variant}` }>{ product.Name }</Link>
                        </h3>
                        <div className="product-content">
                            <p>{ product.ShortDescription }</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-3 col-6 order-md-last order-lg-last">
                    <div className="product-list-action">
                        <div className="product-price">
                            <span className="out-price">Start from <del>₹{ product.MinPrice.toFixed( 2 ) }</del>{" "}₹{ product.MinSalePrice.toFixed( 2 ) }</span>
                        </div>
                        <div className="ratings-container">
                            <div className="ratings">
                                <div className="ratings-val" style={ { width: product.AvgRating * 20 + '%' } }></div>
                                <span className="tooltip-text">{ product.AvgRating.toFixed( 2 ) }</span>
                            </div>
                            <span className="ratings-text">( { product.TotalRatings } Reviews )</span>
                        </div>
                        <Link to={ `/product/${product.Variant}` } className="btn-product btn-cart btn-select">
                            <span>select options</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ProductListView;
