import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Qty(props) {
    const { value, adClass = "", max = 10000, changeQty } = props;
    const [current, setCurrent] = useState(value);
    const location = useLocation();

    useEffect(() => {
        // Sync with external value
        if (value !== current && location.pathname.includes('/product')) {
            setCurrent(value);
        }
    }, [value, current, location.pathname]);

    function increment() {
        if (current < max) {
            const newValue = current + 1;
            setCurrent(newValue);
            if (changeQty) {
                changeQty(newValue);
            }
        }
    }

    function decrement() {
        if (current > 1) {
            const newValue = current - 1;
            setCurrent(newValue);
            if (changeQty) {
                changeQty(newValue);
            }
        }
    }

    function handleChange(e) {
        const newValue = e.target.value;
        if (newValue === '') {
            // Allow clearing the field but handle in onBlur
            setCurrent('');
        } else if (/^\d+$/.test(newValue)) {
            // Only set if the value is a valid number
            const numericValue = parseInt(newValue, 10);
            if (numericValue >= 1 && numericValue <= max) {
                setCurrent(numericValue);
                if (changeQty) {
                    changeQty(numericValue);
                }
            }
        }
    }

    function handleBlur(e) {
        let newValue = parseInt(e.target.value, 10);
        if (isNaN(newValue) || newValue < 1) {
            newValue = value;
        } else if (newValue > max) {
            newValue = max;
        }
        setCurrent(newValue);
        if (changeQty) {
            changeQty(newValue);
        }
    }

    return (
        <div className={`product-details-quantity ${adClass}`}>
            <div className="input-group input-spinner">
                <div className="input-group-prepend">
                    <button
                        style={{ minWidth: '26px' }}
                        className="btn btn-decrement btn-spinner"
                        onClick={decrement}
                        type="button"
                    >
                        <i className="icon-minus"></i>
                    </button>
                </div>
                <input
                    type="text"
                    className="form-control text-center"
                    min="1"
                    max={max}
                    value={current}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <div className="input-group-append">
                    <button
                        style={{ minWidth: '26px' }}
                        className="btn btn-increment btn-spinner"
                        type="button"
                        onClick={increment}
                    >
                        <i className="icon-plus"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Qty;
