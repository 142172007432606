import { configureStore } from "@reduxjs/toolkit";
import categoriesReducer from './Categories';
import CartSlice from "./CartSlice";
import productsReducer from "./Products";
import WhishlistSlice from "./WhishlistSlice";

const store = configureStore({
    reducer:{
        cartState: CartSlice,
        whishlist: WhishlistSlice,
        products: productsReducer,
        categories: categoriesReducer
    }
})

export default store;