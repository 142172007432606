import React from 'react';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import InnerImageZoom from 'react-inner-image-zoom';
import OwlCarouselSlider from './owl-carousel';
import useSize from './useSize';

function GalleryDefault ( props ) {
    const { product, adClass = "product-gallery-vertical", productImages } = props;

    const width = useSize();

    function changeBgImage ( e, image, index ) {
        let imgs = document.querySelectorAll( '.product-main-image img' );
        for ( let i = 0; i < imgs.length; i++ ) {
            imgs[ i ].src = image;
        }

        document.querySelector( '.product-image-gallery .active' ).classList.remove( 'active' );

        document.querySelector( '.product-main-image' ).setAttribute( 'index', index );
        e.currentTarget.classList.add( 'active' );
    }
    
    if ( !product ) {
        return <div></div>
    }

    return (
        <>
            <div className={ `product-gallery ${adClass}` }>
                <div className="row m-0">
                    {
                        width > 525 ?
                        <>
                            <figure className="product-main-image" index="0">
                                <InnerImageZoom 
                                    src={ productImages[ 0 ].URL }
                                    zoomSrc={ productImages[ 0 ].URL }
                                    zoomType="hover"
                                    hideCloseButton={true}
                                    zoomPreload={true}
                                />
                            </figure>
                            <div id="product-zoom-gallery" className="product-image-gallery">
                                {
                                    productImages.map( ( item, index ) =>
                                    <button className={ `product-gallery-item ${0 === index ? 'active' : ''}` } key={ product._id + '-' + index } onClick={ e => changeBgImage( e, `${item.URL}`, index ) }>
                                        <div className="img-wrapper h-100">
                                            <img src={ item.URL } alt="product back" />
                                        </div>
                                    </button>
                                    )
                                }
                            </div>
                        </>
                        :
                        <OwlCarouselSlider adClass="owl-simple intro-slider owl-nav-inside">
                        {
                            productImages.map( ( item, index ) =>
                            <div className='intro-slide'>
                                <button className={ `active` } key={ product._id + '-' + index } >
                                    <div className="img-wrapper h-100">
                                        <img src={ item.URL } alt="product back" />
                                    </div>
                                </button>
                            </div>
                            )
                        }
                        </OwlCarouselSlider>
                    }
                </div>
            </div>
        </>
    )
}

export default React.memo( GalleryDefault );