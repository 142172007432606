import React, { useState, useEffect, useRef } from 'react';

const ColorDropdown = ({ SKU, onChange, colors }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  let Color = [];
  // Split into two-character chunks
  for (let i = 0; i < SKU.length; i += 2) {
    Color.push(SKU.substr(i, 2));
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const selectedColor = (Color) => {
    const defaultColor = colors && colors.ColorOptions.find(color => color.Key === Color[2]);

    return defaultColor || null;
  };

  const foundColor = selectedColor(Color);

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (value) => {
    setIsOpen(false);
    onChange(value);
  };

  return (
    <div className="custom-select" ref={dropdownRef}>
      <div className="select-header" onClick={toggleOptions}>
        <div className="shipping-opt product-nav product-nav-dots">
          <span
            style={{backgroundColor: foundColor.ColorHexCode}}
            className={ `${( foundColor.Key === Color[2] ? 'active ' : '' )}` }
          ></span>
          <p>{foundColor.Name}</p>
        </div>
        <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9662;</span>
      </div>
      {isOpen && (
        <div className="select-options">
          {colors.ColorOptions.length > 0 && (
            <div className="select-option product-nav-dots">
              {colors.ColorOptions.map((color, ind) => (
                <div
                  className={`option ${color.Key === Color[2] ? "selected" : ""}`}
                  key={ind}
                  onClick={() => selectOption(color.Key)}
                >
                  <div key={ ind } className={`shipping-opt`}>
                    <span
                      style={{backgroundColor: color.ColorHexCode}}
                      className={ `${( color.Key === Color[2] ? 'active ' : '' )}` }
                    ></span>
                    <p>{color.Name}</p>
                    <p class="arrow ">▾</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ColorDropdown;
