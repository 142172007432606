import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../utils/ApiRequest";

//fetch wishlist items
export const fetchWishlistItems = createAsyncThunk("whistlist/fetchWishlistItems", async () => {
    try{
        const response = await instance.get(`/shop/wishlists/list`);
        return response.data;
    }catch(error){
        throw error.message;

    }
  });

  //add to wishlist

  export const AddToWishList = createAsyncThunk("whistlist/AddToWishList", async (data) => {
    try{
        const response = await instance.post(`/shop/wishlists/add`, data);
        return response.data;
    }catch(error){
        throw error;

    }
  });

   //delete wishlist item

   export const DeleteWishListItem = createAsyncThunk("whistlist/DeleteWishListItem", async (SKU) => {
    try{
        const response = await instance.delete(`/shop/wishlists/${SKU}/delete`);
        return response.data;
    }catch(error){
        throw error;

    }
  });

const whishListSlice = createSlice({
    name: 'whistlist',
    initialState: {
        whishlist: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        addToWhistList: (state, action) => {
            state.whishlist.push(action.payload);
        },
        removeFromWhishlist: (state, action) => {
           const removeItem = state.whishlist.filter(item => item._id !== action.payload);
           state.whishlist = removeItem;
        }
    },
    extraReducers: (builder) => {
        builder
      .addCase(fetchWishlistItems.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWishlistItems.fulfilled, (state, action) => {
        state.status = 'success';
        state.whishlist = action.payload;
      })
      .addCase(fetchWishlistItems.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      })
      .addCase(AddToWishList.fulfilled, (state, action) => {
        state.status = 'Item Added Successfully';
        state.error = action.error;
      })
      .addCase(DeleteWishListItem.fulfilled, (state, action) => {
        state.status = 'Item Deleted Successfully';
        state.error = action.error;
      })
    }
})

export default whishListSlice.reducer;

export const {addToWhistList, removeFromWhishlist} = whishListSlice.actions;