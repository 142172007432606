import React, { useRef, useEffect } from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


function OwlCarouselSlider ( props ) {
    const {options } = props;
    const carouselRef = useRef( null );
    const defaultOptions = {
        items: 1,
        loop: true,
        margin: 0,
        responsiveClass: "true",
        nav: false,
        navText: [ '<i class="icon-angle-left">', '<i class="icon-angle-right">' ],
        dots: true,
        smartSpeed: 200,
        autoplay: true,
    }

    useEffect( () => {
        if ( props.onChangeRef ) {
            props.onChangeRef( carouselRef );
        }
    }, [ carouselRef, props ] );

    let settings = options || defaultOptions;

    return (
        props.children !== undefined ?
            props.children.length > 0 || ( props.children && props.children.length === undefined ) ?
                <OwlCarousel ref={ carouselRef } className={ `owl-theme` } {...settings}>
                    { props.children }
                </OwlCarousel >
                : ""
            : ""
    );
}

export default OwlCarouselSlider;